<template>
  <div
    class="contentArea min-h-full overflow-auto w-full"
    style="color: #6a7e91"
  >
    <div class="bg-white flex flex-col p-6 h-full">
      <div class="header-bar mb-2">
        <div
          class="flex flex-col lg:flex-row gap-4 justify-between grow-0 my-3"
        >
          <div
            class="flex gap-5 flex-col overflow-visible relative"
            @click="showDropdown = !showDropdown"
            v-click-outside="
              () => {
                if (showDropdown === true) {
                  showDropdown = false;
                }
              }
            "
          >
            <div
              class="publish-btn pointer"
              :class="publish + '-dropdown'"
              id="selectDiv"
            >
              <div>
                {{ publish }}
              </div>
              <div v-if="publish === 'Publish'">
                <img src="../../assets/images/elements/PublishDropdown.svg" />
              </div>
              <div v-if="publish === 'Unpublish'">
                <img src="../../assets/images/elements/UnpublishDropdown.svg" />
              </div>
              <div v-if="publish === 'Schedule'">
                <img src="../../assets/images/elements/ScheduleDropdown.svg" />
              </div>
            </div>
            <transition name="fade">
              <div class="dropdown-content" v-show="showDropdown">
                <p
                  class="option cursor-pointer py-2"
                  v-for="option in options"
                  :class="option"
                  :key="option"
                  @click="handleClick(option)"
                >
                  {{ option }}
                </p>
              </div>
            </transition>
          </div>
          <div class="flex justify-end items-center">
            <div
              class="h-full mr-4 flex justify-center items-center text-black"
            >
              Enter page no.
            </div>
            <input
              type="number"
              v-model="pageExport"
              @input="updateExportLink"
              class="input-page"
            />

            <input
              ref="copyData"
              class="text-center text-input w-2/3 export-link-input"
              readonly
              :value="exportLink"
            />
            <button
              type="submit"
              class="border-button border-button-pri btn create-btn copy-btn"
              @click="copyToClipboard"
            >
              <img
                src="../../assets/images/icon/copy.svg"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
        <transition name="fade">
          <div v-show="publish === 'Schedule'">
            <div class="flex gap-5">
              <div class="date-container">
                <div class="mr-2">Start date:</div>
                <div class="date-selector">{{ data.start_date }}</div>
                <img src="../../assets/images/icon/calendar.svg" />
              </div>

              <div class="date-container">
                <div class="mr-2">End date:</div>

                <div
                  class="date-selector"
                  :value="inputValue"
                  v-on="inputEvents"
                >
                  {{ data.end_date }}
                </div>
                <img src="../../assets/images/icon/calendar.svg" />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="content-container flex justify-center items-center flex-col">
        <div class="catalog-container">
          <div>
            <img
              v-if="currentPage > firstPage"
              src="../../assets/images/elements/left_arrow.svg"
              class="pointer"
              @click="
                $router
                  .replace(
                    `/catalog/${catalog_id}/${(
                      Number(currentPage) - 1
                    ).toString()}`
                  )
                  .catch(() => {})
              "
              width="20"
            />
            <div v-else></div>
          </div>
          <div
            class="pic-container rounded flex justify-center items-center mx-24"
          >
            <div class="container2">
              <div v-show="!show">
                <cropper
                  :src="`https://imagedelivery.net/2Yp5a6Nf7jjRnrY2BFKltA/${
                    data.page_img[currentPage - 1]
                  }/public`"
                  @change="change"
                  ref="cropper"
                  :class="{ active: !show }"
                  :stencil-props="{
                    previewClass: 'cropper_stencil',
                    scalable: false,
                    line: {},
                    handlersClasses: {},
                    resizable: false,
                  }"
                  :canvas="false"
                  :style="cropperStyle"
                  :default-position="{ left: coor.x, top: coor.y }"
                  :defaultSize="defaultSize"
                  :background-wrapper-component="
                    $options.components.CustomBackgroundWrapper
                  "
                  background-class="cropper_background"
                  foreground-class="cropper_foreground"
                />
              </div>
              <img
                v-show="show"
                :src="`https://imagedelivery.net/2Yp5a6Nf7jjRnrY2BFKltA/${
                  data.page_img[currentPage - 1]
                }/public`"
                width="500"
                class="catalog-pic"
                id="myImgId"
                @click="
                  () => {
                    show = !show;
                    GetCoordinates();
                  }
                "
                ref="img"
                @load="getImageInfo"
              />
            </div>
          </div>
          <div>
            <img
              v-if="currentPage < lastPage"
              src="../../assets/images/elements/right_arrow.svg"
              class="pointer"
              @click="
                $router
                  .replace(
                    `/catalog/${catalog_id}/${(
                      Number(currentPage) + 1
                    ).toString()}`
                  )
                  .catch(() => {})
              "
            />
            <div v-else></div>
          </div>
        </div>
        <div class="mt-10">
          <button
            class="rounded border-none crop-size-btn"
            @click="openCropSizeCatalogModal"
          >
            Adjust standard crop size
          </button>
          <div class="mt-4 font-bold flex justify-center items-center">
            <span>Page</span>
            <form @submit.prevent="changePageInput">
              <input
                v-click-outside="outsideInput"
                type="number"
                class="border-none page-input rounded"
                v-model="pageInput"
                required
              /><span>/ {{ lastPage }}</span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--------------Modal--------------->
    <transition name="fade"
      ><SmallBlankModal
        v-if="showCropSizeCatalogModal"
        @close="closeCropSizeCatalogModal"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-10 topic">
              Set standard catalog’s crop size
            </div>
            <div class="flex w-full justify-center items-center mb-8">
              <div class="flex w-1/3 justify-center items-center gap-1">
                <label class="mr-4">Width</label>
                <input
                  class="text-center mb-4 text-input w-1/3"
                  type="number"
                  v-model="cropWidth"
                  min="0"
                  max="100"
                />
                <span>%</span>
              </div>
              <div class="flex w-1/3 justify-center items-center gap-1">
                <label class="mr-4">Height</label>
                <input
                  class="text-center mb-4 text-input w-1/3"
                  type="number"
                  v-model="cropHeight"
                  min="0"
                  max="100"
                />
                <span>%</span>
              </div>
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeCropSizeCatalogModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="border-button border-button-pri btn create-btn text-center"
                  @click="setCropSize"
                >
                  Set crop size
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
      <SmallBlankModal
        v-if="showSetScheduleModal"
        @close="closeSetScheduleModal"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-10 topic">
              Scheduling "{{ data.catalog_name }}"
            </div>
            <div class="flex w-full justify-center items-center mb-8 flex-col">
              <div class="flex justify-between items-center mb-4 w-2/5">
                <span>Start date: </span
                ><DatePicker
                  v-model="startDate"
                  :popover="popover"
                  @input="checkStartDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-container" v-on="inputEvents">
                      <input
                        class="date-selector"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <img src="../../assets/images/icon/calendar.svg" />
                    </div>
                  </template>
                </DatePicker>
              </div>
              <div class="flex justify-between items-center w-2/5">
                <span>End date: </span
                ><DatePicker
                  v-model="endDate"
                  :popover="popover"
                  @input="checkEndDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-container" v-on="inputEvents">
                      <input
                        class="date-selector"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <img src="../../assets/images/icon/calendar.svg" />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeSetScheduleModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="border-button border-button-pri btn create-btn text-center"
                  @click="setSchedule"
                >
                  Schedule now
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
    </transition>
    <!--------------Modal--------------->
  </div>
</template>
    
    <script>
import SmallBlankModal from "../../components/smallblankModal.vue";
import { DatePicker } from "v-calendar";
import PublishCatalog from "../elements/ag-grid-table/table_component/PublishCatalog.vue";
import Clipboard from "clipboard";
import { Cropper } from "vue-advanced-cropper";
import moment, { defaultFormat } from "moment";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.classic.css";
import CustomBackgroundWrapper from "../elements/CustomBackgroundCrop.vue";
import axios from "../../axios";
import { catalogBaseurl, catalogLiffUrl } from "../../util/baseUrl";
export default {
  data() {
    return {
      pageExport: null,
      publish: "",
      showDropdown: false,
      options: ["Publish", "Unpublish", "Schedule"],
      scheduleDetail: {},

      img: {
        showHeight: 0,
        showWidth: 0,
        height: 0,
        width: 0,
      },
      coor: { x: 0, y: 0 },
      cropStyle: {
        display: "none",
      },
      showSetScheduleModal: false,
      showStartDate: new Date(),
      showEndDate: new Date(),
      show: true,
      catalog_id: 1,
      firstPage: 1,
      lastPage: 5,
      currentPage: 0,
      pageInput: 1,
      startDate: new Date(),
      endDate: new Date(),
      popover: { visibility: "click" },
      isPublish: { catalog_id: 0, is_publish: 1 },
      lineliffUrl: "",
      showCropSizeCatalogModal: false,
      cropWidth: 0,
      cropHeight: 0,
      cropperStyle: {},
      data: {},
      catalog_id: "",
      exportLink: "",
    };
  },
  components: {
    PublishCatalog,
    DatePicker,
    SmallBlankModal,
    Cropper,
    CustomBackgroundWrapper,
  },

  computed: {
    defaultSize: function () {
      return {
        height: (this.img.height / 100) * this.cropHeight,
        width: (this.img.width / 100) * this.cropWidth,
      };
    },
  },

  methods: {
    updateExportLink() {
      console.log("pageExport: ", this.pageExport);
      if (this.pageExport != 0 && this.pageExport !== null) {
        if (this.pageExport < this.firstPage) {
          this.pageExport = this.firstPage;
        }
        if (this.pageExport > this.lastPage) {
          this.pageExport = this.lastPage;
        }
        this.exportLink =
          this.lineliffUrl +
          `?catalog_id=${this.$route.params.catalog_id}` +
          `&page=${this.pageExport}`;
      } else {
        console.log("asdasdasdasdasdasdasd");
        this.exportLink =
          this.lineliffUrl + `?catalog_id=${this.$route.params.catalog_id}`;
      }
    },
    checkStartDate() {
      console.log(this.startDate);
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
    },
    checkEndDate() {
      if (this.endDate < this.startDate) {
        this.startDate = this.endDate;
      }
    },
    async handleClick(option) {
      this.publish = option;
      if (option === "Schedule") {
        this.openSetScheduleModal();
      } else {
        let status;
        option === "Publish" ? (status = "Publish") : (status = "Unpublish");
        this.isPublish.is_publish = status;
        try {
          await axios
            .put(
              `${catalogBaseurl()}/api/catalog/updateCatalog`,
              {
                catalog_id: this.catalog_id,
                status: option.toLowerCase(),
                start_date: null,
                end_date: null,
              },
              {
                headers: {
                  "ngrok-skip-browser-warning": "asd",
                },
              }
            )
            .then((res) => console.log("updated: ", res));
          await this.getData();
        } catch (err) {
          // if (err.response.data.message) {
          //   alert(err.response.data.message);
          // } else {
          //   alert(err);
          // }
          this.$vs.notify({
            title: "error message",
            text: `${
              err.response.data.message ? err.response.data.message : err
            }`,
            color: "danger",
            icon: "error",
            position: "top-right",
          });
        }
      }
      console.log("isPublish: ", this.isPublish);
    },
    async setSchedule() {
      try {
        console.log("data to send: ", {
          catalog_id: this.catalog_id,
          status: "schedule",
          start_date: moment(this.startDate).startOf("D").toISOString(),
          end_date: moment(this.endDate).endOf("D").toISOString(),
        });
        await axios
          .put(
            `${catalogBaseurl()}/api/catalog/updateCatalog`,
            {
              catalog_id: this.catalog_id,
              status: "schedule",
              start_date: moment(this.startDate).startOf("D").toISOString(),
              end_date: moment(this.endDate).endOf("D").toISOString(),
            },
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => console.log("updated: ", res));
        this.showSetScheduleModal = false;
        this.startDate = new Date().toISOString();
        this.endDate = new Date().toISOString();
        await this.getData();
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
    },
    showPublish(publish) {
      console.log("manage catalog: ", publish);

      this.scheduleDetail = publish;
      console.log("scheduleDetail: ", this.scheduleDetail);
      const name = this.rowData.filter((data) => {
        console.log("data", data);
        if (data.catalog_id == this.scheduleDetail.catalog_id) {
          return data;
        } else {
          return;
        }
      });
      console.log("name: ", name);
      this.scheduleDetail.catalog_name = name[0].catalog_name;
      console.log("scheduleDetail: ", this.scheduleDetail);
      console.log("publish.is_publish: ", publish.is_publish);
      if (publish.is_publish === "Schedule") {
        this.openSetScheduleModal();
      }
    },
    openSetScheduleModal() {
      this.showSetScheduleModal = true;
    },
    async closeSetScheduleModal() {
      await this.getData();
      this.showSetScheduleModal = false;
    },
    FindPosition(oElement) {
      if (typeof oElement.offsetParent != "undefined") {
        for (
          var posX = 0, posY = 0;
          oElement;
          oElement = oElement.offsetParent
        ) {
          posX += oElement.offsetLeft;
          posY += oElement.offsetTop;
        }
        return [posX, posY];
      } else {
        return [oElement.x, oElement.y];
      }
    },
    GetCoordinates(e) {
      var myImg = document.getElementById("myImgId");
      var PosX = 0;
      var PosY = 0;
      var ImgPos;
      ImgPos = this.FindPosition(myImg);
      if (!e) var e = window.event;
      if (e.pageX || e.pageY) {
        PosX = e.pageX;
        PosY = e.pageY;
      } else if (e.clientX || e.clientY) {
        PosX =
          e.clientX +
          document.body.scrollLeft +
          document.documentElement.scrollLeft;
        PosY =
          e.clientY +
          document.body.scrollTop +
          document.documentElement.scrollTop;
      }
      PosX = PosX - ImgPos[0];
      PosY = PosY - ImgPos[1];

      this.coor.x =
        (PosX * this.img.width) / this.img.showWidth -
        this.defaultSize.width / 2;
      this.coor.y =
        (PosY * this.img.height) / this.img.showHeight -
        this.defaultSize.height / 2;
      // console.log(this.coor);

      this.$refs.cropper.reset();
    },
    getImageInfo() {
      const img = this.$refs.img;
      this.img.showHeight = img.height;
      this.img.showWidth = img.width;
      this.cropperStyle = {
        height: img.height.toString() + "px",
        width: img.width.toString() + "px",
      };
      console.log(this.img);
      this.$vs.loading.close();
    },
    change({ coordinates, canvas, image }) {
      // console.log(coordinates);
      this.img.height = image.height;
      this.img.width = image.width;
      let height = (coordinates.height / image.height) * 100;
      height = height.toString() + "%";
      let width = (coordinates.width / image.width) * 100;
      width = width.toString() + "%";
      let left = (coordinates.left / image.width) * 100;
      left = left.toString() + "%";
      let top = (coordinates.top / image.height) * 100;
      top = top.toString() + "%";
      console.log("height: ", height);
      console.log("width: ", width);
      console.log("left: ", left);
      console.log("top: ", top);
      console.log("---------------------------------");
    },
    changePageInput() {
      if (this.pageInput < this.firstPage) {
        this.pageInput = this.firstPage;
        this.$router
          .replace(`/catalog/${this.catalog_id}/${this.firstPage}`)
          .catch(() => {});
      } else if (this.pageInput > this.lastPage) {
        this.pageInput = this.lastPage;
        this.$router
          .replace(`/catalog/${this.catalog_id}/${this.lastPage}`)
          .catch(() => {});
      } else {
        this.$router
          .replace(`/catalog/${this.catalog_id}/${this.pageInput}`)
          .catch(() => {});
      }
    },
    outsideInput() {
      this.pageInput = this.currentPage;
    },
    async setCropSize() {
      // const { image } = this.$refs.cropper;
      // console.log("image: ", image);
      // this.defaultSize.height = (image.height / 100) * this.cropHeight;
      // console.log("height: ", this.defaultSize.height);
      // this.defaultSize.width = (image.width / 100) * this.cropWidth;
      this.show = true;
      console.log(this.defaultSize);
      await this.$refs.cropper.refresh();
      this.showCropSizeCatalogModal = false;
      let data = {
        catalog_id: this.catalog_id,
        crop_size_height: this.cropHeight.toString(),
        crop_size_width: this.cropWidth.toString(),
      };
      console.log("data: ", data);
      console.log("url: ", `${catalogBaseurl()}/api/catalog/updateCatalog`);
      try {
        await axios
          .put(
            `${catalogBaseurl()}/api/catalog/updateCatalog`,
            {
              catalog_id: this.catalog_id,
              crop_size_height: this.cropHeight,
              crop_size_width: this.cropWidth,
            },
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => console.log("updated: ", res));
        await this.getData();
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
      // setTimeout(() => (this.show = false), 500);
    },
    openCropSizeCatalogModal() {
      // this.cropHeight = (
      //   (this.defaultSize.height / image.height) *
      //   100
      // ).toFixed(2);
      // this.cropWidth = ((this.defaultSize.width / image.width) * 100).toFixed(
      //   2
      // );

      this.showCropSizeCatalogModal = true;
    },
    closeCropSizeCatalogModal() {
      this.cropHeight = this.data.crop_size_height;
      this.cropWidth = this.data.crop_size_width;
      this.showCropSizeCatalogModal = false;
    },
    changeStatus(publish) {
      publish.catalog_id = this.$route.params.catalog_id;
      console.log("publish: ", publish);
      publish.is_publish === "Schedule"
        ? (this.isPublish.is_publish = 2)
        : publish.is_publish === "Publish"
        ? (this.isPublish.is_publish = 1)
        : (this.isPublish.is_publish = 0);
      console.log("change to:", this.isPublish);
      if (this.isPublish.is_publish == 2) {
        console.log("sss");
        this.showPublish(publish);
      }
    },
    copyToClipboard() {
      const copyText = this.$refs.copyData;
      const clipboard = new Clipboard(copyText);

      clipboard.on("success", () => {
        console.log("Copied to clipboard");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        console.log("Failed to copy to clipboard");
        clipboard.destroy();
      });

      // Trigger the copy action
      copyText.select();
      document.execCommand("copy");
    },
    async getData() {
      try {
        this.data = await axios
          .get(
            `${catalogBaseurl()}/api/catalog/getCatalog/${this.catalog_id}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => res.data.data);
        this.data.start_date = moment(
          this.data.start_date ? this.data.start_date : new Date()
        ).format("DD/MM/YYYY");
        this.data.end_date = moment(
          this.data.end_date ? this.data.end_date : new Date()
        ).format("DD/MM/YYYY");
        this.publish =
          this.data.status === "publish"
            ? "Publish"
            : this.data.status === "unpublish"
            ? "Unpublish"
            : "Schedule";
        console.log("publish: ", this.publish);
        this.lastPage = this.data.page_img.length;
        this.cropWidth = this.data.crop_size_width;
        this.cropHeight = this.data.crop_size_height;
        console.log(this.cropHeight, this.cropWidth);
        this.currentPage = this.$route.params.page;
        this.pageInput = this.currentPage;
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
  },
  watch: {
    "$route.params.page"(currentPage, nextPage) {
      this.$vs.loading({ color: "#DDDDDD" });
      console.log("page change");
      this.currentPage = this.$route.params.page;
      this.pageInput = this.currentPage;
      this.show = true;
      this.$refs.cropper.refresh();
    },
  },
  async mounted() {
    this.$vs.loading({ color: "#DDDDDD" });
    this.lineliffUrl = catalogLiffUrl();
    const page = this.$route.params.page;
    this.catalog_id = this.$route.params.catalog_id;
    this.exportLink =
      this.lineliffUrl + `?catalog_id=${this.$route.params.catalog_id}`;
    await this.getData();
    await this.$store.dispatch("general/push_activePage", {
      name: this.data.catalog_name,
    });
    if (page < this.firstPage) {
      this.$router
        .replace(`/catalog/${this.catalog_id}/${this.firstPage}`)
        .catch(() => {});
    } else if (page > this.lastPage) {
      this.$route
        .replace(`/catalog/${this.catalog_id}/${this.firstPage}`)
        .catch(() => {});
    }
    this.currentPage = page;
    console.log("Page:", page);
    this.pageInput = page;
    this.isPublish = {
      catalog_id: this.$route.params.catalog_id,
      is_publish: 1,
    };
    // this.publish =
    //   this.isPublish.is_publish === 1
    //     ? "Publish"
    //     : this.isPublish.is_publish === 0
    //     ? "Unpublish"
    //     : "Schedule";
    this.getImageInfo();
    console.log("defaultSize: ", this.defaultSize);
    this.showStartDate = moment(this.startDate).format("DD/MM/YYYY");
    console.log(this.showStartDate);
    this.showEndDate = moment(this.endDate).format("DD/MM/YYYY");
    console.log(this.showEndDate);
  },
};
</script>
  
  <style lang="scss" scoped>
.header-bar {
  min-height: 90px !important;
}

.dropdown-content {
  width: 130px;
  // min-width: 190px;
  border-radius: 7px;
  // padding: 1px 1px;
  border: 1px solid black;
  overflow: hidden;
  position: absolute;
  top: 32px;

  background-color: white;
  .option {
    text-align: center;
    line-height: normal;
    background: white;
  }
}

.Publish,
.Unpublish,
.Schedule {
  width: 110px;
  border: 0;
  margin: 10px 10px;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  cursor: pointer;
}
.Publish,
.Publish-dropdown {
  color: #2cbe3f;
  background-color: #beffc7 !important;
}

.Unpublish,
.Unpublish-dropdown {
  background-color: #ffbebe !important;
  color: #be2c2c;
}
.Schedule,
.Schedule-dropdown {
  background-color: #e3ddff !important;
  color: #7b60fc;
}
#selectDiv {
  width: 130px;
  height: 32px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  border-radius: 7px;
  text-align: center;
  div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    img {
      width: 10px;
    }
  }
}
.Publish:hover {
  color: #2cbe3f;
  background-color: #beffc7;
}

.Unpublish:hover {
  background-color: #ffbebe;
  color: #be2c2c;
}
.Schedule:hover {
  background-color: #e3ddff;
  color: #7b60fc;
}

.pic-container {
  // min-width: 200px;
  // min-height: 200px;
  padding: 30px;
  border: 1px solid #92a3b7;
  .container2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.date-container {
  background-color: #c2d7e5;
  color: #6a7e91;
  padding: 2px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .date-selector {
    background-color: #c2d7e5;
    color: #6a7e91;
    border: 0;
    width: 85px;
  }
}
// .btn {
//   padding: 5px 50px;
// }

.input-page {
  padding: 5px 5px;
  width: 60px;
  border: 0;
  background-color: #92a3b7;
  color: #ffffff;
  border-radius: 0.25rem;
  margin-right: 20px;
  text-align: center;
  font-weight: 700;
}
.close-btn2 {
  background-color: #eeeeee;
}

.catalog-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  img,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.create-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #92a3b7;
  color: #ffffff !important;
  max-width: 150px;
  width: 45%;
  border: 0 !important;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem !important;
}
.copy-btn {
  padding: 0px 20px !important;
  width: 70px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 32px !important;
}

.export-link-input {
  background-color: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 32px !important;
  border: 1px solid #92a3b7;
  font-weight: 600;
  width: 350px !important;
  overflow: scroll !important;
  direction: rtl;
}
.crop-size-btn {
  background-color: #ff9446;
  padding: 10px 20px;
  color: #fff;
}
.publish-btn {
  height: 32px;
}
.page-input {
  width: 50px;
  padding: 2px 5px;
  background-color: #92a3b7;
  color: #fff;
  margin: 0px 5px;
  text-align: center;
  font-weight: 700;
}

.cropper {
  display: block !important;
  position: absolute;
  top: -100%;
  right: -100%;
  width: 500px;
  /* height: 3000px; */
  opacity: 0;
  /* display: none; */
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: black;
  }
  &__stencil {
    border: solid 2px red;
  }
}
.cropper_stencil {
  border: solid 2px red !important;
}

.active {
  //   width: 350px;
  //   height: 350.25px;

  position: relative !important;
  top: 0% !important;
  right: 0% !important;
  opacity: 100 !important;
}
.topic {
  font-size: 20px;
  color: #000;
}
.cancel-btn {
  background: rgba(29, 32, 35, 0.25);
  max-width: 150px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  border-radius: 0.25rem !important;
  color: #000 !important;
}
</style>
  
  